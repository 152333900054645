import React from 'react';
import { graphql } from 'gatsby';
import {
  ItemsListIndexPage,
  PageHero,
  PageWithSubNav,
  Section,
} from 'docComponents';
import pageHeroData from '../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Layering"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Layering"
        tierThree="Overview"
      />

      <Section>
        <ItemsListIndexPage
          data={data}
          listType="visual"
          tierTwo="layering"
          listLayout="3"
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query guidelinesLayeringCodeIndexQuery($tierOne: String = "Guidelines") {
    ...indexList
  }
`;
